.image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.modalImage {
  max-width: 700px;
}

.container {
  position: relative;
  height: 40px;
  width: 40px;
  cursor: pointer;
  overflow: hidden;
}

.initials {
  height: 40px;
  width: 40px;
  font-size: medium;
  font-weight: 700;
  color: white;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.modalContainer {
  width: 300px;
  min-height: 100px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.editButton {
  margin-left: 10px;
  background-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  color: var(--primary-color);
}

@media (max-width: 767px) {
  .modalImage {
    max-width: 300px;
  }
}
