.container {
  width: 100%;
  min-height: 60px;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.chip_cont {
  width: auto;
}

.chip_box {
  min-width: var(--chip-width);
  height: var(--chip-height);
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  color: var(--primary-color);
  border: 1px var(--primary-color) solid;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover_box:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.chip_label {
  font-size: var(--para-xs);
  text-transform: capitalize;
}

.active_chip_box {
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
}

.input_check {
  display: none;
}

.disabled {
  cursor: not-allowed;
  color: black;
  background-color: lightgray;
  border: 1px lightgray solid;
}
