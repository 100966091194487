.container {
  width: 100%;
  height: 50px;
  transition: height ease 0.2s;
  overflow: hidden;
}

.heading {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
}

.content {
  position: relative;
  width: 100%;
  padding-left: 20px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.content::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  border-left: 2px var(--medium-blue-color) solid;
}

.header {
  position: relative;
}

.drop_icon {
  position: absolute;
  top: 30%;
  right: 10px;

  margin-left: auto;
  color: var(--medium-gray-color);
}
