.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  z-index: var(--navbar-z);
  background-color: #fafbfc;
  box-shadow: var(--shadow-m);
  display: flex;
  align-items: center;
  padding: 10px;
}

.toggle_btn {
  padding: 10px;
  width: 50px;
  background-color: transparent;
}

.logoImage {
  height: 100%;
  width: 120px;
  margin-left: 10px;
}

.tooltip_text {
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
}
