.container {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.label {
  font-size: var(--para-m);
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--secondary-color);
}

.error {
  color: var(--error-color);
  font-size: var(--para-xs);
  flex-basis: 10px;
  text-transform: capitalize;
}
