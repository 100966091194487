.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.radio_cont {
  width: auto;
}
.radio_box {
  min-width: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio_label {
  margin-left: 10px;
  font-size: var(--para-s);
  text-transform: capitalize;
}

.circle_out {
  width: var(--radio-width);
  height: var(--radio-width);
  position: relative;
}
.circle_box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px var(--medium-gray-color) solid;
  z-index: 4;
  background-color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 50%;
  background-color: rgba(0, 123, 255, 0.2);
  transform: scale(1);
  transition: transform ease 0.3s;
}
.radio_box:hover > .circle_out > .overlay {
  transform: scale(2);
}

.active_circle_box {
  border: 1px var(--primary-color) solid;
}
.circle {
  width: calc(var(--radio-width) / 2);
  height: calc(var(--radio-width) / 2);
  border-radius: 50%;
  background-color: var(--primary-color);
}

.input_radio {
  display: none;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

.disabled > .circle_out > .overlay {
  transform: scale(0) !important;
}
