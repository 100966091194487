.profile_pic_button {
  width: 70px;
  height: 40px;
  margin-left: auto;
  border: none;
  display: flex;
  background-color: transparent;
}
.profile_btn_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile_btn_icon {
  font-size: 30px;
  color: var(--medium-gray-color);
}
.profile_drop_icon {
  font-size: 10px;
  margin-left: 5px;
  color: var(--secondary-color);
}
.overlay_cont {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--modal-z);
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
}

.user_card {
  position: absolute;
  width: 200px;
  min-height: 150px;
  background-color: white;
  border-radius: 6px;
  box-shadow: var(--shadow-m);
  z-index: 2;
  transform-origin: top right;
}

.animateIn {
  animation: expand 0.3s ease forwards;
}
.animateOut {
  animation: shrink 0.3s ease forwards;
}

.userHead {
  display: flex;
  min-height: 100px;
  /* border: 1px red solid; */
  align-items: stretch;
  background-color: var(--light-primary-color);
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.user_image_box {
  flex-basis: 20%;
}

.user_img_cont {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user_img_cont > img {
  width: 100%;
  height: 100%;
}

.logout_button {
  margin: 10px;
  margin-left: auto;
}

.logout_button:hover {
  background-color: var(--light-primary-color);
  color: white;
  transition: 0.2s;
}

.user_desc {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  padding-left: 10px;
  justify-content: center;
}
.user_profile_icon {
  font-size: 50px;
  color: white;
}

.user_name {
  font-size: var(--para-m);
  font-weight: bold;
}

.user_email {
  margin-top: 5px;
  font-size: var(--para-s);
}

@keyframes expand {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    opacity: 0.2;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
