.timePicker {
  text-align: left;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--overlay-z);
  background-color: rgba(0, 0, 0, 0);
}

.picker_box {
  position: absolute;
  z-index: var(--modal-z);
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-m);
  padding: 10px 0;
  border-radius: 6px;
  background-color: white;
}

.list_box {
  display: flex;
  overflow: hidden;
  max-height: 250px;
}

.btn {
  background-color: var(--primary-color);
  color: white;
  width: 80px;
  height: 40px;
  margin: 0px 10px;
  align-self: flex-end;
}

.top {
  top: calc(100% + 5px);
}
.bottom {
  bottom: calc(100% + 5px);
}

.selectList {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin: 10px 0px;
  overflow: scroll;
}
.selectList::-webkit-scrollbar-button {
  display: none;
}

.selectList::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.selectList::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.selectItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.activeItem:hover,
.activeItem {
  background-color: var(--primary-color);
  color: white;
}
.selectItem {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
