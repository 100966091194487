.sm_f {
  height: 80px;
}

.md_f {
  height: 120px;
}

.lg_f {
  height: 150px;
}

.attachment {
  width: 150px;
  height: 100%;
  margin: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: var(--heading-l);
  color: var(--dark-gray-color);
  flex-shrink: 0;
}

.attach_text {
  font-size: var(--para-s);
  font-weight: 500px;
  color: var(--primary-color);
}
.file_input {
  border: 1px black dotted;
  color: var(--primary-color);
  font-size: var(--title-1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow-x: scroll;
  padding: 10px;
}

.upload_txt {
  font-size: var(--title-2);
  font-weight: 400;
  margin-left: 10px;
  color: black;
}
