.page_fall_cont {
  width: 100%;
  height: 100%;
}

.child_fall_cont {
  width: 100%;
  height: 100%;
  display: flex;
}

.error_icon,
.error_icon_s {
  font-size: 80px;
  color: var(--dark-gray-color);
}

.error_icon_s {
  font-size: 20px;
}
.error_text {
  margin-top: 10px;
  font-size: var(--para-m);
  color: var(--dark-gray-color);
}

.error_text_child {
  margin-top: 0;
  margin-left: 10px;
  font-size: var(--para-s);
  color: var(--dark-gray-color);
}
