.select {
  text-align: left;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--generic-overlay);
  background-color: rgba(0, 0, 0, 0);
}

.option_box {
  position: fixed;
  z-index: var(--generic-overlay_item);
  width: 100%;
  max-height: 300px;
  box-shadow: var(--shadow-m);
  padding: 10px 0;
  border-radius: 6px;
  background-color: white;
  overflow-y: auto;
}
.s_option {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0 10px;
  font-size: var(--para-m);
  border: none;
  background-color: white;
}

.s_option:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.loader {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.on_loader {
  position: absolute;
  top: 30%;
  right: 5px;
}
