.form_container {
  width: 100%;
  padding: 10px;
  flex-grow: 1;
}

.form_card {
  box-shadow: var(--shadow-m);
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  background-color: white;
  padding: 20px 10px;
  margin: 0 auto;
  border-radius: 7px;
}

@media (min-width: 1024px) {
  .form_container {
    padding: 30px;
  }
}
