.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--modal-z);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: calc(var(--modal-z) - 500);
}

.content {
  position: absolute;
  z-index: calc(var(--modal-z) - 100);
  width: auto;
  height: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: var(--shadow-m);
  transform: translateY(-200%);
}

.heading {
  width: 100%;
  height: 50px;
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: var(--medium-gray-color);
  font-size: var(--title-2);
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
}

.close {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-200%);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
}
