.container {
  --input-padding: 10px;
  width: 100%;
  display: block;
  position: relative;
  border-radius: 5px;
  margin-bottom: 6px;
}

.sm {
  height: 30px;
}

.md {
  height: 50px;
}

.lg {
  height: 80px;
}

.icon {
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 15px;
}

.icon_success {
  color: var(--success-color);
}

.icon_error {
  color: var(--error-color);
}
.input {
  width: 100%;
  padding: var(--input-padding);
  height: 100%;
  border: 1px var(--secondary-color) solid;
  border-radius: 5px;
  font-weight: 400;
  color: var(--dark-gray-color);
  box-sizing: border-box;
  display: block;
}

.secondary {
  background-color: white;
  border: 1px #e0e5e9 solid;
  border-radius: 3px;
  color: #222a33;
  font-weight: 500;
}

.success.success {
  border-radius: 5px;
  border-width: 2px;
  border-color: var(--success-color);
}

.error.error {
  border-radius: 5px;
  border-width: 2px;
  border-color: var(--error-color);
}

.text_area {
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.select {
  padding: 10px;
  font-size: var(--para-m);
}

.fill {
  height: 100%;
}

.option {
  width: 100%;
  height: 50px;
  border: 1px red solid;
}

.clear_btn {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  right: 10px;
  top: 10px;
}
