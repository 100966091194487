.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: calc(var(--modal-z) + 100);
}
.datePicker {
  text-align: left;
}

.picker_box {
  position: fixed;
  z-index: 200;
  min-width: 300px;
  box-shadow: var(--shadow-m);
  border-radius: 6px;
  background-color: white;
  --action-height: 60px;
  --week-height: 40px;
  --general-padding: 10px;
}

.action_row {
  width: 100%;
  height: var(--action-height);
  padding: var(--general-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.action_btn {
  width: 80px;
  height: 40px;
  background-color: white;
  border: none;
  font-size: var(--para-s);
  font-weight: bold;
}

.page_box {
  width: auto;
  height: 40px;
  display: flex;
}

.page_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  font-size: var(--para-m);
}
.week {
  width: 100%;
  height: var(--week-height);
  border-bottom: 1px lightgray solid;
  display: flex;
  padding: 0 var(--general-padding);
}
.week > span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--para-s);
  color: var(--primary-color);
}
.days_box,
.years_box {
  width: 100%;
  height: calc(100% - var(--week-height) - var(--action-height));
  flex-flow: column nowrap;
  padding: var(--general-padding);
}

.years_box {
  border-top: 1px lightgray solid;
}

.day_row,
.month_row,
.year_row {
  width: 100%;
  height: 40px;
  display: flex;
  flex: 1;
}

.cell,
.year_cell,
.month_cell,
.day_cell {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--para-s);
  color: var(--dark-gray-color);
}

.year_cell:hover,
.month_cell:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.year_cell_active.year_cell_active,
.month_cell_active.month_cell_active {
  background-color: var(--primary-color);
  color: white;
  font-weight: bolder;
}
.day_cell:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
}

.day_cell_active.day_cell_active {
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  font-weight: bolder;
}

.top {
  top: calc(100% + 5px);
}
.bottom {
  bottom: calc(100% + 5px);
}
